import * as Yup from "yup";

export const validationSchema = Yup.object({

    givenName: Yup.string()
        .required("First name is required.")
        .min(2, "First name minimum length is 2 characters.")
        .max(100, "First name maximum length is 100 characters.")
    ,
    familyName: Yup.string()
        .required("Last name is required.")
        .min(2, "Last name minimum length is 2 characters.")
        .max(100, "Last name maximum length is 100 characters.")
    ,
    organizationName: Yup.string()
        .required("Company/institution/organization is required.")
        .min(2, "Company/institution/organization minimum length is 2 characters.")
        .max(100, "Company/institution/organization maximum length is 100 characters.")
    ,
    email: Yup.string()
        .required("E-mail address is required.")
        .email("E-mail address must be a valid SMTP address.")
        .max(255, "E-mail address maximum length is 255 characters.")
    ,
    title: Yup.string()
        .required("Title is required.")
    ,
    laboratoryFounded: Yup.string()
        .required("Lab founded date is required.")
    ,
    laboratoryName: Yup.string()
        .required("Lab name is required.")
    ,
    estimatedSpend: Yup.string()
        .required("Estimated spend is required.")
    ,
    areaOfResearch: Yup.string()
        .required("Area of research is required.")
    ,
    referralAnnotation: Yup.string()
        .required("Please enter how you heard about Bio X Cell.")
    ,

});
