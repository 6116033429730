import React from "react";

export default function AuthCallback() {
    return (
        <>
            <div>
            </div>
        </>
    );
};
