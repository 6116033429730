import React from "react";

export default function Select(props) {
    const { id, label, description, register, errors, onChange, records, valueProperty, labelProperty, visible, disabled } = props;

    let isVisible = visible;
    if (typeof (isVisible) === "undefined") isVisible = true;

    const Options = ({ records, valueProperty, labelProperty }) => {
        if (!valueProperty) {
            valueProperty = "id";
        }

        if (!labelProperty) {
            labelProperty = "name";
        }

        let options = records.map((record, index) => {
            return <option key={index} value={record[valueProperty]}>{record[labelProperty]}</option>;
        })

        return options;
    }

    const registration = register(id, { onChange });

    return (
        <div className={`form-floating ${!isVisible ? "d-none" : ""}`}>
            <select id={id}
                {...registration}
                className={`form-select ${errors[id] ? "is-invalid" : ""}`}
                disabled={disabled}>
                <option value=""></option>
                {records ? <Options records={records} valueProperty={valueProperty} labelProperty={labelProperty} /> : props.children}
            </select>
            <label htmlFor={id} className="form-label">{label}</label>
            <div className="invalid-feedback">{errors[id]?.message}</div>
            {description ? <div className="form-text">{description}</div> : null}
        </div>
    );
}
