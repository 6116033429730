import React from "react";
import "./Cart.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export default function Cart({ quote }) {
    const locale = Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

    const CartItem = ({ name, price, retailPrice, nonProfit }) => {
        const locale = Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

        return (
            <tr>
                <td>{name}</td>
                {nonProfit ?
                    <>
                        <td className="regular-price-strikethrough" style={{ textAlign: "right" }}>{locale.format(retailPrice)}</td>
                        <td style={{ textAlign: "right" }}>{locale.format(price)}</td>
                    </> :
                    <>
                        <td colSpan="2" style={{ textAlign: "right" }}>{locale.format(price)}</td>
                    </>
                }
                <td>{nonProfit ? <FontAwesomeIcon icon={faExclamationCircle} className="discount-icon" title="non-profit pricing" /> : null}</td>
            </tr>
        );
    };

    if (quote.customProductionAmountOrder) {
        return (
            <>
                <h3>Estimated Cost</h3>

                <p className="mt-3">
                    <strong>
                        For custom production amounts, a Bio X Cell representative will contact you with a detailed quote.
                    </strong>
                </p>
            </>
        );
    }

    return (
        <>
            <h3>Estimated Cost <FontAwesomeIcon icon={faExclamationTriangle} className="disclaimer-icon" /></h3>

            <table className="table">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th colSpan="2" style={{ textAlign: "right" }}>Price</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {quote.lineItems?.length > 0 ?
                        quote.lineItems.map((lineItem, index) => {
                            if (!lineItem.sku.startsWith("SHP")) {
                                return <CartItem key={index} {...lineItem} />;
                            }

                            return null;
                        }) : <tr><td>&nbsp;</td><td colSpan="2">&nbsp;</td></tr>}
                </tbody>
                <tfoot>
                    <tr>
                        <td><strong>Subtotal</strong></td>
                        <td colSpan="2" style={{ textAlign: "right" }}><strong>{locale.format(quote.subTotal ?? 0)}</strong></td>
                        <td></td>
                    </tr>
                    {quote.shippingAmount > 0 ?
                        <tr>
                            <td>{quote.shippingDescription}</td>
                            <td colSpan="2" style={{ textAlign: "right" }}>{locale.format(quote.shippingAmount)}</td>
                            <td></td>
                        </tr> : null}
                    {quote.discountAmount > 0 ?
                        <>
                            <tr>
                                <td>Discount {quote.discountCode}</td>
                                <td colSpan="2" style={{ textAlign: "right" }}>({locale.format(quote.discountAmount)})</td>
                                <td></td>
                            </tr>
                        </>
                    : null}
                    <tr>
                        <td><strong>Total <FontAwesomeIcon icon={faExclamationCircle} className={`non-profit-discount-icon ${!quote.nonProfit ? "d-none" : ""}`} /></strong></td>
                        <td colSpan="2" style={{ textAlign: "right" }}><strong>{locale.format(quote.total ?? 0)}</strong></td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>

            <ul className="notes">
                <li>
                    <FontAwesomeIcon icon={faExclamationTriangle} className="disclaimer-icon" />
                    Actual cost may vary based on pilot, final requested production amount, and selected services.
                </li>
                <li className={!quote.nonProfit ? "d-none" : ""}>
                    <FontAwesomeIcon icon={faExclamationCircle} className="discount-icon" />
                    Item includes an academic/non-profit discount.
                </li>
                <li className={!quote.nonProfit ? "d-none" : ""}>
                    <FontAwesomeIcon icon={faExclamationCircle} className="non-profit-discount-icon" />
                    Total savings of <strong>{locale.format(quote.nonProfitDiscountAmount)}</strong> with academic/non-profit discount.
                </li>
            </ul>
        </>
    );
}