import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import Layout from "./Layout";

import AuthCallback from "./auth-callback/AuthCallback";
import CustomOrder from "./custom-order/CustomOrder";
import CertificateOfAnalysis from "./certificate-of-analysis/CertificateOfAnalysis";
import ServiceRequest from "./service-request/ServiceRequest";
import NewLabDiscount from "./new-lab-discount/NewLabDiscount";
import EmploymentOpening from "./employment-opening/EmploymentOpening";
import TaxExemption from "./tax-exemption/TaxExemption";
import NewsletterSignUp from "./newsletter-sign-up/NewsletterSignUp";
import AccountsPayable from "./accounts-payable/AccountsPayable";

const rootElement = document.getElementById("root");

const props = {
};

const Page = () => {
    let route = window.location.pathname;
    route = route.replace(/\/+$/, "");

    const params = (new URL(document.location)).searchParams;
    const id = params.get("id");
    const catalogCode = params.get("catalogCode");
    const lotNumber = params.get("lotNumber");
    const vesselSizeMg = params.get("vesselSizeMg");
    const vesselCount = params.get("vesselCount");

    switch (route) {
        case "/auth-callback":
            return <AuthCallback {...props} />;
        case "/custom-order":
            return <CustomOrder {...props} />;
        case "/certificate-of-analysis":
            return <CertificateOfAnalysis {...props} catalogCode={catalogCode} lotNumber={lotNumber} vesselSizeMg={vesselSizeMg} vesselCount={vesselCount} />;
        case "/service-request":
            return <ServiceRequest {...props} />;
        case "/new-lab-discount":
            return <NewLabDiscount {...props} />;
        case "/employment-opening":
            return <EmploymentOpening {...props} id={id} />;
        case "/tax-exemption":
            return <TaxExemption {...props} />;
        case "/newsletter-sign-up":
            return <NewsletterSignUp {...props} />;
        case "/accounts-payable":
            return <AccountsPayable {...props} />;

        default:
            fetch("/api/settings", {
                method: "GET",
                headers: { Accept: "application/json", "Content-Type": "application/json" },
            })
                .then(response => response.text())
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.errors && response.errors.length) {
                        console.error(response);
                        return;
                    }

                    if (response.apiBaseUrl.includes("localhost")) {
                        //window.location.replace("https://localhost:44302/employment-opening/?id=1c116ffc-ce7b-4041-b5fe-62a7825fda46");
                        window.location.replace("https://localhost:44302/certificate-of-analysis");
                        return;
                    }

                    window.location.replace("https://bioxcell.com");
                })
                .catch(error => {
                    console.error(error);
                });
    }
};

ReactDOM.render(
    <React.StrictMode>
        <Layout>
            <Page />
        </Layout>
    </React.StrictMode>,
    rootElement);

serviceWorker.unregister();
