import React, { useState, useEffect } from "react";
import "./CustomOrder.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//import useYupValidationResolver from "./useYupValidationResolver";
import { validationSchema } from "./customOrderValidation";
import Input from "../components/forms/Input";
import Select from "../components/forms/Select";
import TextArea from "../components/forms/TextArea";
import Checkbox from "../components/forms/Checkbox";
import RadioButton from "../components/forms/RadioButton";
import MapTestingDescription from "./MapTestingDescription";
import Cart from "./Cart";

export default function CustomOrder() {
    const [apiBaseUrl, setApiBaseUrl] = useState(null);
    const [apiKey, setApiKey] = useState(null);
    const [regions, setRegions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [completed, setCompleted] = useState(false);
    const [isUsOrCa, setIsUsOrCa] = useState(false);
    const [selectedCountryId, setSelectedCountryId] = useState(0);
    const [hasIggSpecies, setHasIggSpecies] = useState(false);
    const [isCustomOrder, setIsCustomOrder] = useState(false);
    const [mapTesting, setMapTesting] = useState(0);
    const [isMycoplasmaTestingIncluded, setIsMycoplasmaTestingIncluded] = useState(false);
    const [quote, setQuote] = useState({});
    const [requiredIsotypeTesting, setRequiredIsotypeTesting] = useState(false);
    const [order, setOrder] = useState({});
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [isUsUser, setIsUsUser] = useState(false);

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors, ...formState } } = useForm({ resolver: yupResolver(validationSchema) });
    const inputProps = { register, errors };
    const { isDirty, isSubmitting } = formState;

    useEffect(() => {
        document.title = "Bio X Cell Antibody Production Services Order";

        let apiBaseUrl = "";
        let apiKey = "";

        (async () => {
            await fetch("https://ipinfo.io/json?token=0f81c20378f92f", {
                method: "GET",
                headers: { Accept: "application/json", "Content-Type": "application/json" },
            })
                .then(response => response.text())
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.errors && response.errors.length) {
                        console.error(response);
                        return;
                    }

                    console.log(response);
                    setIsUsUser(response.country === "US");
                })
                .catch(error => {
                    console.error(error);
                });

            await fetch("/api/settings", {
                method: "GET",
                headers: { Accept: "application/json", "Content-Type": "application/json" },
            })
                .then(response => response.text())
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.errors && response.errors.length) {
                        console.error(response);
                        return;
                    }

                    apiBaseUrl = response.apiBaseUrl;
                    setApiBaseUrl(apiBaseUrl);
                    apiKey = response.apiKey;
                    setApiKey(apiKey);
                })
                .catch(error => {
                    console.error(error);
                });

            await fetch(`${apiBaseUrl}/countries`, {
                method: "GET",
                headers: { Accept: "application/json", "Content-Type": "application/json", "x-api-key": apiKey },
            })
                .then(response => response.text())
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.errors && response.errors.length) {
                        console.error(response);
                        return;
                    }

                    setCountries(response);
                })
                .catch(error => {
                    console.error(error);
                });

            await fetch(`${apiBaseUrl}/regions`, {
                method: "GET",
                headers: { Accept: "application/json", "Content-Type": "application/json", "x-api-key": apiKey },
            })
                .then(response => response.text())
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.errors && response.errors.length) {
                        console.error(response);
                        return;
                    }

                    setRegions(response);
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    }, []);

    const handleIggSpeciesChange = (event) => {
        const iggSpecies = event.target.value;
        const isSet = iggSpecies !== "" && iggSpecies !== "Unknown";
        setHasIggSpecies(isSet);
        if (!isSet) {
            setValue("isotype", iggSpecies);
            setValue("lightChainDesignation", iggSpecies);
        }
        const isotype = getValues("isotype");
        if (iggSpecies === "Unknown" || isotype === "Unknown") {
            setValue("includeIsotypeTesting", true);
            setRequiredIsotypeTesting(true);
        } else {
            setRequiredIsotypeTesting(false);
        }
        updateCart();
    };

    const handleIsotypeChange = (event) => {
        setValue("isotype", event.target.value);
        const iggSpecies = getValues("iggSpecies");
        if (event.target.value === "Unknown" || iggSpecies === "Unknown") {
            setValue("includeIsotypeTesting", true);
            setRequiredIsotypeTesting(true);
        } else {
            setRequiredIsotypeTesting(false);
        }
        updateCart();
    };

    const handleCountryChange = (event) => {
        const countryId = event.target.value;
        const usId = countries.find(record => record.isoCode2 === "US");
        const caId = countries.find(record => record.isoCode2 === "CA");
        const usOrCa = countryId === usId?.id.toString() || countryId === caId?.id.toString();

        if (usOrCa) {
            setValue("regionName", "");
        } else {
            setValue("regionId", "");
        }

        setIsUsOrCa(usOrCa);
        setSelectedCountryId(countryId);
    };

    const handleProductionAmountChange = (event) => {
        const productionAmount = event.target.value;
        console.log(productionAmount);

        const isCustom = productionAmount === "-1";

        setIsCustomOrder(isCustom);

        if (!isCustom) {
            setValue("customProductionAmount", "");
            setValue("customProductionAmountUom", "Mg");
        }

        updateCart();
    };

    const handleAggregationScreeningChange = (event) => {
        if (event.target.checked) {
            setValue("aggregationScreening", true);
        } else {
            setValue("aggregationScreening", false);
        }
        updateCart();
    };

    const handleFreezeAndStoreChange = (event) => {
        if (event.target.checked) {
            setValue("freezeAndStore", true);
        } else {
            setValue("freezeAndStore", false);
        }
        setValue("freezeAndShip", false);
        updateCart();
    };

    const handleFreezeAndShipChange = (event) => {
        if (event.target.checked) {
            setValue("freezeAndShip", true);
        } else {
            setValue("freezeAndShip", false);
        }
        setValue("freezeAndStore", false);
        updateCart();
    };
    
    const handleIncludeIsotypeControlChange = (event) => {
        if (event.target.checked) {
            setValue("includeIsotypeControl", true);
        } else {
            setValue("includeIsotypeControl", false);
        }
        updateCart();
    };

    const handleIncludeDilutionBufferChange = (event) => {
        if (event.target.checked) {
            setValue("includeDilutionBuffer", true);
        } else {
            setValue("includeDilutionBuffer", false);
        }
        updateCart();
    };

    const handleIsotypeTestingChange = (event) => {
        if (event.target.checked) {
            setValue("includeIsotypeTesting", true);
        } else {
            setValue("includeIsotypeTesting", false);
        }
        updateCart();
    };

    const handleMycoplasmaTestingChange = (event) => {
        if (event.target.checked) {
            setValue("includeMycoplasmaTesting", true);
        } else {
            setValue("includeMycoplasmaTesting", false);
        }
        setIsMycoplasmaTestingIncluded(event.target.checked);
        updateCart();
    };

    const handleMapTesting1Change = (event) => {
        if (event.target.checked) {
            setValue("includeMapTesting1", true);
            setMapTesting(1);
        } else {
            setValue("includeMapTesting1", false);
            setMapTesting(0);
        }
        setValue("includeMapTesting2", false);
        updateCart();
    };

    const handleMapTesting2Change = (event) => {
        if (event.target.checked) {
            setValue("includeMapTesting2", true);
            setMapTesting(2);
        } else {
            setValue("includeMapTesting2", false);
            setMapTesting(0);
        }
        setValue("includeMapTesting1", false);
        updateCart();
    };

    const handleEmailChange = (event) => {
        setValue("email", event.target.value);
        updateCart();
    };

    const handleFedexAccountNumberChange = (event) => {
        setValue("fedexAccountNumber", event.target.value);
        updateCart();
    };

    const handleDiscountCodeChange = (event) => {
        setValue("discountCode", event.target.value.toUpperCase());
        updateCart();
    };

    const sanitizeRequest = (request) => {
        request.customProductionAmountUnitOfMeasureId = request.customProductionAmountUom === "Mg" ? 1 : 3;
        request.reorderReminderDays = +request.reorderReminderDays;
        request.regionId = +request.regionId;
        request.countryId = +request.countryId;
        request.productionAmount = +request.productionAmount;
        request.customProductionAmount = +request.customProductionAmount;

        console.log(JSON.stringify(request, null, 2));

        return request;
    };

    const updateCart = () => {
        const request = sanitizeRequest(getValues());

        (async () => {
            await fetch(`${apiBaseUrl}/custom-orders/estimate`, {
                method: "POST",
                headers: { Accept: "application/json", "Content-Type": "application/json", "x-api-key": apiKey },
                body: JSON.stringify(request),
            })
                .then(response => {
                    if (response.status === 401 || response.status === 403) {
                        document.location.href = `https://bxcell.com`;
                    }

                    return response.text()
                })
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.status >= 400) {
                        console.error(response);
                        throw new Error(`Error calling API.`);
                    }

                    setQuote(response);
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    };

    const handleOnSubmit = (request) => {
        setSubmitDisabled(true);

        request = sanitizeRequest(request);

        (async () => {
            await fetch(`${apiBaseUrl}/custom-orders`, {
                method: "POST",
                headers: { Accept: "application/json", "Content-Type": "application/json", "x-api-key": apiKey },
                body: JSON.stringify(request),
            })
                .then(response => {
                    if (response.status === 401) {
                        document.location.href = `https://bxcell.com`;
                    }

                    return response.text()
                })
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.status >= 400) {
                        setSubmitDisabled(false);
                        console.error(response);
                        throw new Error(`Error calling API.`);
                    }

                    console.log(response);

                    setOrder(response);
                    setCompleted(true);

                    setSubmitDisabled(false);
                })
                .catch(error => {
                    setSubmitDisabled(false);
                    console.error(error);
                });
        })();
    };

    return (
        <>

            <div className="container">

                <div style={{ display: completed ? "" : "none" }}>

                    <h1>Thank You!</h1>

                    <p>
                        {order.givenName},
                    </p>

                    <p>
                        Thank you for submitting a quote request!  Your quote number is {order.id}.
                    </p>

                    <p>
                        You should receive an e-mail with the quotation shortly.  
                        If you have additional questions or do not receive the quote, please reach out to <a href="mailto:contractservices@bioxcell.com">contractservices@bioxcell.com</a>.  
                    </p>

                    <p>
                        Bio X Cell has been custom producing antibodies for our clients for 25 years and is pleased to have the opportunity to assist you.
                    </p>

                    <p>
                        <a href="/custom-order" className="btn btn-primary">Submit Another Custom Antibody Production Request</a>
                    </p>

                </div>

                <div style={{ display: completed ? "none" : "" }}>

                    <div className="row">
                        <div className={`col-md-${isUsUser ? "8" : "12"} mb-3`}>

                            <h1>Antibody Production Service Order</h1>

                            <p>
                                Please complete the following order form to request custom antibody production.
                            </p>

                            <form onSubmit={handleSubmit(handleOnSubmit)}>

                                <div className="accordion" id="form-1">

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="step-1">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#step-1-collapse" aria-expanded="true" aria-controls="step-1-collapse">
                                                <h3>Cell Line Information</h3>
                                            </button>
                                        </h2>
                                        <div id="step-1-collapse" className="accordion-collapse collapse show" aria-labelledby="step-1-heading" data-bs-parent="#form-1">
                                            <div className="accordion-body">

                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="cloneName"
                                                            label="Clone Name"
                                                            {...inputProps} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="antigenName"
                                                            label="Antigen Name"
                                                            {...inputProps} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <Select id="productionAmount"
                                                            label="Amount Requested"
                                                            {...inputProps}
                                                            onChange={handleProductionAmountChange}>
                                                            <option value="50">50 mg</option>
                                                            <option value="100">100 mg</option>
                                                            <option value="200">200 mg</option>
                                                            <option value="500">500 mg</option>
                                                            <option value="1000">1,000 mg</option>
                                                            <option value="-1">Custom Amount</option>
                                                        </Select>
                                                    </div>
                                                    <div className={`col-md-6 mb-3 ${!isCustomOrder ? "d-none" : ""}`}>
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <Input id="customProductionAmount"
                                                                    type="number"
                                                                    label="Custom Amount"
                                                                    description="50 mg minimum."
                                                                    {...inputProps} />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <RadioButton id="customProductionAmountUomMg"
                                                                    name="customProductionAmountUom"
                                                                    label="mg"
                                                                    value="Mg"
                                                                    {...inputProps} />
                                                                <RadioButton id="customProductionAmountUomG"
                                                                    name="customProductionAmountUom"
                                                                    label="g"
                                                                    value="G"
                                                                    {...inputProps} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="productivity"
                                                            label="Productivity (mg/L or g/L)"
                                                            description="We will determine productivity within our system before moving to full scale.  20-30 mg/L minimum."
                                                            {...inputProps} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <Select id="iggSpecies"
                                                            label="Select IgG Species"
                                                            description="If unknown, we will test for an additional charge."
                                                            {...inputProps}
                                                            onChange={handleIggSpeciesChange}>
                                                            <option value="Hamster">Hamster</option>
                                                            <option value="Human">Human</option>
                                                            <option value="Mouse">Mouse</option>
                                                            <option value="Rabbit">Rabbit</option>
                                                            <option value="Rat">Rat</option>
                                                            <option value="Unknown">Unknown</option>
                                                        </Select>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <Select id="isotype"
                                                            label="Select Isotype"
                                                            description="If unknown, we will test for an additional charge."
                                                            {...inputProps}
                                                            disabled={!hasIggSpecies}
                                                            onChange={handleIsotypeChange}>
                                                            <option value="IgG1">IgG1</option>
                                                            <option value="IgG2">IgG2</option>
                                                            <option value="IgG2a">IgG2a</option>
                                                            <option value="IgG2b">IgG2b</option>
                                                            <option value="IgG2c">IgG2c</option>
                                                            <option value="IgG3">IgG3</option>
                                                            <option value="IgG4">IgG4</option>
                                                            <option value="IgM">IgM</option>
                                                            <option value="Unknown">Unknown</option>
                                                        </Select>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <Select id="lightChainDesignation"
                                                            label="Select Light Chain Designation"
                                                            {...inputProps}
                                                            disabled={!hasIggSpecies}>
                                                            <option value="Lambda">Lambda</option>
                                                            <option value="Kappa">Kappa</option>
                                                            <option value="Unknown">Unknown</option>
                                                        </Select>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 mb-3">
                                                        <TextArea id="recommendedMedia"
                                                            label="What is the recommended media for culturing these cells?"
                                                            height={100}
                                                            {...inputProps} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="step-2">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#step-2-collapse" aria-expanded="true" aria-controls="step-2-collapse">
                                                <h3>Additional Services and Products</h3>
                                            </button>
                                        </h2>
                                        <div id="step-2-collapse" className="accordion-collapse collapse show" aria-labelledby="step-2-heading" data-bs-parent="#form-1">
                                            <div className="accordion-body">

                                                <div className="row">
                                                    <div className="col-md-12 mb-3">
                                                        <Checkbox id="aggregationScreening"
                                                            label="Include aggregation screening by size exclusion chromatography"
                                                            {...inputProps}
                                                            onChange={handleAggregationScreeningChange} />

                                                        <Checkbox id="freezeAndStore"
                                                            label="Freeze and store cells for five years"
                                                            {...inputProps}
                                                            onChange={handleFreezeAndStoreChange} />

                                                        <Checkbox id="freezeAndShip"
                                                            label="Freeze cells and ship to me"
                                                            {...inputProps}
                                                            onChange={handleFreezeAndShipChange} />

                                                        <Checkbox id="includeIsotypeControl"
                                                            label="Include InVivoMAb™ isotype control antibody"
                                                            {...inputProps}
                                                            onChange={handleIncludeIsotypeControlChange} />

                                                        <Checkbox id="includeDilutionBuffer"
                                                            label="Include InVivoPure™ dilution buffer"
                                                            {...inputProps} 
                                                            onChange={handleIncludeDilutionBufferChange} />

                                                        <Checkbox id="includeIsotypeTesting"
                                                            label="Include isotype testing"
                                                            {...inputProps}
                                                            disabled={requiredIsotypeTesting}
                                                            onChange={handleIsotypeTestingChange} />

                                                        <span className={`checkbox-note mb-2 ${!requiredIsotypeTesting ? "d-none" : ""}`}>
                                                            Isotype testing required when IgG species or isotype or unknown.
                                                        </span>

                                                        <Checkbox id="includeMycoplasmaTesting"
                                                            label="Include mycoplasma testing"
                                                            {...inputProps}
                                                            onChange={handleMycoplasmaTestingChange} />

                                                        <span className={`checkbox-note mb-2 ${isMycoplasmaTestingIncluded ? "d-none" : ""}`}>
                                                            I will provide documentation stating cells are mycoplasma-free if testing is not requested.
                                                        </span>

                                                        <Checkbox id="includeMapTesting1"
                                                            label="Include MAP testing 1"
                                                            {...inputProps}
                                                            onChange={handleMapTesting1Change} />

                                                        <Checkbox id="includeMapTesting2"
                                                            label="Include MAP testing 2"
                                                            {...inputProps}
                                                            onChange={handleMapTesting2Change} />
                                                    </div>
                                                </div>

                                                <MapTestingDescription mapTesting={mapTesting} />

                                                <div className="row">
                                                    <div className="col-md-12 mb-3">
                                                        <TextArea id="additionalServices"
                                                            label="Any additional services?"
                                                            height={100}
                                                            {...inputProps} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="step-3">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#step-3-collapse" aria-expanded="true" aria-controls="step-3-collapse">
                                                <h3>Contact Information</h3>
                                            </button>
                                        </h2>
                                        <div id="step-3-collapse" className="accordion-collapse collapse show" aria-labelledby="step-3-heading" data-bs-parent="#form-1">
                                            <div className="accordion-body">

                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="givenName"
                                                            label="First Name"
                                                            {...inputProps} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="familyName"
                                                            label="Last Name"
                                                            {...inputProps} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    {/*<div className="col-md-6 mb-3">*/}
                                                    {/*    <Input id="title"*/}
                                                    {/*        label="Title"*/}
                                                    {/*        {...inputProps} />*/}
                                                    {/*</div>*/}
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="organizationLegalName"
                                                            label="Company/Institution/Organization"
                                                            {...inputProps} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="email"
                                                            type="email"
                                                            label="E-Mail Address"
                                                            {...inputProps}
                                                            onChange={handleEmailChange} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="telephone"
                                                            label="Phone Number"
                                                            {...inputProps} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="streetAddress"
                                                            label="Street Address"
                                                            {...inputProps} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="locality"
                                                            label="City"
                                                            {...inputProps} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="streetAddress2"
                                                            label="Suite/Building/Room"
                                                            {...inputProps} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <div className="form-floating">
                                                            <select id="countryId"
                                                                {...register("countryId")}
                                                                className={`form-select ${errors["countryId"] ? "is-invalid" : ""}`}
                                                                onChange={handleCountryChange}>
                                                                <option value=""></option>
                                                                {countries.map((country, index) => {
                                                                    return <option key={index} value={country.id}>{country.name}</option>
                                                                })}
                                                            </select>
                                                            <label htmlFor="countryId" className="form-label">Country</label>
                                                            <div className="invalid-feedback">{errors["countryId"]?.message}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className={`form-floating ${!isUsOrCa ? "d-none" : ""}`}>
                                                            <select id="regionId"
                                                                {...register("regionId")}
                                                                className={`form-select ${errors["regionId"] ? "is-invalid" : ""}`}>
                                                                <option value=""></option>
                                                                {regions.map((region, index) => {
                                                                    if (isUsOrCa && region.countryId.toString() === selectedCountryId) {
                                                                        return <option key={index} value={region.id}>{region.name}</option>;
                                                                    }

                                                                    return null;
                                                                })}
                                                            </select>
                                                            <label htmlFor="regionId" className="form-label">State/Province/Region</label>
                                                            <div className="invalid-feedback">{errors["regionId"]?.message}</div>
                                                        </div>
                                                        <Input id="regionName"
                                                            label="State/Province/Region"
                                                            visible={!isUsOrCa}
                                                            {...inputProps} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="postalCode"
                                                            label="Postal Code"
                                                            {...inputProps} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="step-4">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#step-4-collapse" aria-expanded="true" aria-controls="step-4-collapse">
                                                <h3>Order Details</h3>
                                            </button>
                                        </h2>
                                        <div id="step-4-collapse" className="accordion-collapse collapse show" aria-labelledby="step-4-heading" data-bs-parent="#form-1">
                                            <div className="accordion-body">

                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="fedexAccountNumber"
                                                            label="Fedex Account Number (Optional)"
                                                            description="If you supply a FedEx account, the cost of shipping is reduced."
                                                            {...inputProps}
                                                            onChange={handleFedexAccountNumberChange} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <Input id="discountCode"
                                                            label="Discount Code"
                                                            description="If you have received a Bio X Cell special promotion code, enter it here."
                                                            {...inputProps}
                                                            onChange={handleDiscountCodeChange}/>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <Select id="reorderReminderDays"
                                                            label="Remind me to re-order every"
                                                            description="We will send you an e-mail reminder with a link that fast-tracks the order process."
                                                            {...inputProps}>
                                                            <option value="30">month</option>
                                                            <option value="60">2 months</option>
                                                            <option value="90">3 months</option>
                                                            <option value="120">4 months</option>
                                                            <option value="150">5 months</option>
                                                            <option value="180">6 months</option>
                                                            <option value="210">7 months</option>
                                                            <option value="240">8 months</option>
                                                            <option value="270">9 months</option>
                                                            <option value="300">10 months</option>
                                                            <option value="330">11 months</option>
                                                            <option value="365">12 months</option>
                                                        </Select>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 mb-3">
                                                        <TextArea id="annotation"
                                                            label="Additional Comments"
                                                            height={100}
                                                            {...inputProps} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-12 mt-3 mb-3">
                                        <button className="btn btn-primary w-100" type="submit" disabled={submitDisabled || isSubmitting}>Request Custom Antibody Production</button>
                                        <button className="btn btn-secondary ms-3 d-none" type="button" onClick={reset} disabled={!isDirty || isSubmitting}>Reset</button>
                                    </div>
                                </div>

                            </form>

                        </div>

                        <div className={`col-md-4 mb-3 ${isUsUser ? "" : "d-none"}`}>
                            <div className="col-fixed">
                                <Cart quote={quote} />
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </>
    );
};
