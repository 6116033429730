import * as Yup from "yup";

export const validationSchema = Yup.object({

    catalogCode: Yup.string()
        .required("Product catalog number is required.")

});

    //,
    //lotNumber: Yup.string()
    //    .required("Lot number is required.")
    //,
    //vesselSizeMg: Yup.string()
    //    .required("Vial size is required.")
    //,
    //vesselCount: Yup.string()
    //    .required("Vial count is required.")
