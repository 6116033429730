import * as Yup from "yup";

export const validationSchema = Yup.object({

    cloneName: Yup.string()
        .required("Clone name is required.")
        .max(100, "Clone name maximum length is 100 characters.")
    ,
    antigenName: Yup.string()
        .required("Antigen name is required.")
        .max(100, "Antigen name maximum length is 100 characters.")
    ,
    productionAmount: Yup.string()
        .required("Production amount is required.")
    ,
    customProductionAmount: Yup.string()
        .max(50, "Production amount maximum length is 50 characters.")
    ,
    productivity: Yup.string()
        .matches(/^$|([1-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9])\s?(mg\/L|g\/L|mg\/l|g\/l)/, "Productivity must in the format 999 mg/L or g/L.")
        .max(50, "Productivity maximum length is 50 characters.")
    ,
    iggSpecies: Yup.string()
        .required("Please select an IgG species.")
    ,
    isotype: Yup.string()
        .required("Please select an isotype.")
    ,
    lightChainDesignation: Yup.string()
        .required("Please select a light chain designation.")
    ,
    recommendedMedia: Yup.string()
        //.required("Recommended media for culturing is required.")
        .max(2000, "Recommended media for culturing maximum length is 2,000 characters.")
    ,
    additionalServices: Yup.string()
        .max(2000, "Additional services maximium length is 2,000 characters.")
    ,
    givenName: Yup.string()
        .required("First name is required.")
        .min(2, "First name minimum length is 2 characters.")
        .max(100, "First name maximum length is 100 characters.")
    ,
    familyName: Yup.string()
        .required("Last name is required.")
        .min(2, "Last name minimum length is 2 characters.")
        .max(100, "Last name maximum length is 100 characters.")
    ,
    //title: Yup.string()
    //    //.required("Title is required.")
    //    //.min(2, "Title minimum length is 2 characters.")
    //    .max(100, "Title maximum length is 100 characters.")
    //,
    organizationLegalName: Yup.string()
        .required("Company/institution/organization is required.")
        .min(2, "Company/institution/organization minimum length is 2 characters.")
        .max(100, "Company/institution/organization maximum length is 100 characters.")
    ,
    email: Yup.string()
        .required("E-mail address is required.")
        .email("E-mail address must be a valid SMTP address.")
        .max(255, "E-mail address maximum length is 255 characters.")
    ,
    telephone: Yup.string()
        .required("Phone number is required.")
        .min(10, "Phone number minimum length is 10 characters.")
        .max(20, "Phone number maximum length is 20 characters.")
    ,
    streetAddress: Yup.string()
        .required("Street address is required.")
        .min(6, "Street address minimum length is 6 characters.")
        .max(100, "Street address maximum length is 100 characters.")
    ,
    streetAddress2: Yup.string()
        .max(100, "Suite/building/room maximum length is 100 characters.")
    ,
    locality: Yup.string()
        .required("City is required.")
        .min(2, "City minimum length is 2 characters.")
        .max(100, "City maximum length is 100 characters.")
    ,
    postalCode: Yup.string()
        .required("Postal code is required.")
        .min(5, "Postal code minimum length is 5 characters.")
        .max(10, "Postal code maximum length is 10 characters.")
    ,
    countryId: Yup.string()
        .required("Country is required.")
    ,
    fedexAccountNumber: Yup.string()
        .matches(/^$|\d{9}/, "FedEx account number must be a 9-digit number.")
    ,
    discountCode: Yup.string()
        .max(20, "Discount code maximum length is 20 characters.")
    ,
    annotation: Yup.string()
        .max(2000, "Comments maximium length is 2,000 characters.")

});
