import React from "react";

export default function Input(props) {
    const { id, type, label, description, register, errors, visible, disabled, onChange, step, autoFocus } = props;

    let isVisible = visible;
    if (typeof(isVisible) === "undefined") isVisible = true;

    const inputType = type ? type : "text";

    let events = {};
    if (onChange) {
        events = { onChange };
    }

    console.log('here here here' + autoFocus);

    return (
        <div className={`form-floating ${!isVisible ? "d-none" : ""}`}>
            <input id={id} type={inputType} placeholder={label} step={step}
                {...register(id)}
                className={`form-control ${errors[id] ? "is-invalid" : ""}`}
                autoFocus={autoFocus}
                disabled={disabled}
                {...events} />
            <label htmlFor={id}>{label}</label>
            <div className="invalid-feedback">{errors[id]?.message}</div>
            {description ? <div className="form-text">{description}</div> : null}
        </div>
    );
}
