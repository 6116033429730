import React from "react";

export default function Checkbox(props) {
    const { id, label, register, visible, disabled, onChange } = props;

    let isVisible = visible;
    if (typeof(isVisible) === "undefined") isVisible = true;

    const registration = register(id, { onChange });

    return (
        <div className={`form-check ${!isVisible ? "d-none" : ""}`}>
            <input className="form-check-input"
                id={id}
                type="checkbox"
                {...registration}
                disabled={disabled} />
            <label className="form-check-label" htmlFor={id}>{label}</label>
        </div>
    );
}
