import React, { useState, useEffect } from "react";
import "./TaxExemption.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./taxExemptionValidation";
import Input from "../components/forms/Input";
import Recaptcha from "react-google-recaptcha"

export default function TaxExemption() {
    const [apiBaseUrl, setApiBaseUrl] = useState(null);
    const [apiKey, setApiKey] = useState(null);
    const [regions, setRegions] = useState([]);
    const [completed, setCompleted] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [record, setRecord] = useState({});
    const [files, setFiles] = useState([]);

    const { register, handleSubmit, formState: { errors, ...formState } } = useForm({ resolver: yupResolver(validationSchema) });
    const inputProps = { register, errors };
    const { isSubmitting } = formState;

    const [formValidationRequest, setFormValidationRequest] = useState(null);
    const [formValidationFailed, setFormValidationFailed] = useState(false);

    useEffect(() => {
        document.title = "Bio X Cell Tax Exemption Request";

        let apiBaseUrl = "";
        let apiKey = "";

        (async () => {
            await fetch("/api/settings", {
                method: "GET",
                headers: { Accept: "application/json", "Content-Type": "application/json" },
            })
                .then(response => response.text())
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.errors && response.errors.length) {
                        console.error(response);
                        return;
                    }

                    apiBaseUrl = response.apiBaseUrl;
                    setApiBaseUrl(apiBaseUrl);
                    apiKey = response.apiKey;
                    setApiKey(apiKey);
                })
                .catch(error => {
                    console.error(error);
                });

            await fetch(`${apiBaseUrl}/regions`, {
                method: "GET",
                headers: { Accept: "application/json", "Content-Type": "application/json", "x-api-key": apiKey },
            })
                .then(response => response.text())
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.errors && response.errors.length) {
                        console.error(response);
                        return;
                    }

                    setRegions(response);
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    }, []);

    const handleOnSubmit = (model) => {
        setFormValidationFailed(false);
        setSubmitDisabled(true);

        let request = new FormData();

        request.append("formValidationRequest", formValidationRequest);

        request.append("formFile", files[0]);
        request.append("fileName", files[0].name);
        request.append("givenName", model.givenName);
        request.append("familyName", model.familyName);
        request.append("organizationName", model.organizationName);
        request.append("email", model.email);
        request.append("exemptionNumber", model.exemptionNumber);
        request.append("regionName", model.regionName);
        request.append("exemptionType", model.exemptionType);
        request.append("startDate", model.startDate);
        request.append("endDate", model.endDate);

        (async () => {
            await fetch(`${apiBaseUrl}/forms/tax-exemption`, {
                method: "POST",
                headers: { "x-api-key": apiKey },
                body: request,
            })
                .then(response => {
                    return response.text()
                })
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.status >= 400) {
                        setSubmitDisabled(false);
                        console.error(response);
                        throw new Error(`Error calling API.`);
                    }

                    if (!response.formValidationSuccess) {
                        setFormValidationFailed(true);
                        setSubmitDisabled(false);
                        return;
                    }

                    setRecord(response);
                    setCompleted(true);

                    setSubmitDisabled(false);
                })
                .catch(error => {
                    setSubmitDisabled(false);
                    console.error(error);
                });
        })();
    };

    const handleRecaptchaChange = (value) => {
        setFormValidationRequest(value);
    };

    return (
        <>

            <div style={{ display: completed ? "" : "none" }}>

                <h1>Thank You!</h1>

                <p>
                    {record.givenName},
                </p>

                <p>
                    Thank you for submitting your tax exemption certificate.
                </p>

            </div>

            <div style={{ display: completed ? "none" : "" }}>

                <h1>Tax Exemption Request</h1>

                <div className={`${formValidationFailed ? "" : "d-none"} alert alert-danger`}>
                    Please check the reCAPTCHA checkbox below.
                </div>

                <p>
                    Please complete the form below and upload a digital copy of your tax exemption certificate.
                </p>

                <form onSubmit={handleSubmit(handleOnSubmit)}>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Input id="givenName"
                                label="First Name"
                                {...inputProps} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input id="familyName"
                                label="Last Name"
                                {...inputProps} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Input id="organizationName"
                                label="Organization"
                                {...inputProps} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input id="email"
                                type="email"
                                label="E-Mail Address"
                                {...inputProps} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <div className="form-floating">
                                <select id="regionName"
                                    {...register("regionName")}
                                    className={`form-select ${errors["regionId"] ? "is-invalid" : ""}`}>
                                    <option value=""></option>
                                    {regions.map((region, index) => {
                                        if (region.countryId === 235) {
                                            return <option key={index} value={region.name}>{region.name}</option>;
                                        }

                                        return null;
                                    })}
                                </select>
                                <label htmlFor="regionName" className="form-label">Tax Exemption State</label>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <Input id="exemptionNumber"
                                label="Tax Exemption Number (Optional)"
                                {...inputProps} />
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="form-floating">
                                <select id="exemptionType"
                                    {...register("exemptionType")}
                                    className={`form-select ${errors["taxExemptionType"] ? "is-invalid" : ""}`}>
                                    <option value=""></option>
                                    <option key="Resale" value="Resale">Resale</option>
                                    <option key="Resale-Drop Shipment" value="Resale-Drop Shipment">Resale-Drop Shipment</option>
                                    <option key="Government Entity" value="Government Entity">Government Entity</option>
                                    <option key="Non-Profit Entity" value="Non-Profit Entity">Non-Profit Entity</option>
                                    <option key="School/University" value="School/University">School/University</option>
                                    <option key="Direct Pay" value="Direct Pay">Direct Pay</option>
                                </select>
                                <label htmlFor="exemptionType" className="form-label">Exemption Type (Optional)</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <Input id="startDate"
                                type="date"
                                label="Tax Exemption Start Date (Optional)"
                                {...inputProps} />
                        </div>
                        <div className="col-md-3 mb-3">
                            <Input id="endDate"
                                type="date"
                                label="Tax Exemption End Date (Optional)"
                                {...inputProps} />
                        </div>
                        <div className="col-md-3 mb-3">
                            <input className="form-control" type="file" onChange={(event) => setFiles(event.target.files)} placeholder="PDF" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <Recaptcha sitekey="6LcgQtciAAAAAPYj_MRfIcJ6zshQ9nokwtewlV98" onChange={handleRecaptchaChange} />
                        </div>
                    </div>

                    <div className={`${formValidationFailed ? "" : "d-none"} alert alert-danger`}>
                        Please check the reCAPTCHA checkbox above.
                    </div>

                    <div className="row">
                        <div className="col-md-12 mt-3 mb-3">
                            <button className="btn btn-primary w-100" type="submit" disabled={submitDisabled || isSubmitting}>Submit Tax Exemption Certificate</button>
                        </div>
                    </div>

                </form>

            </div>

        </>
    );
};
