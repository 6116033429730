//import React from "react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import "./layout.css";

export default function Layout(props) {
    //return (
    //    <>
    //        <a href="https://bxcell.com"><img src="/images/bio-x-cell-logo.svg" alt="Bio X Cell" className="heading" /></a>
    //        <div className="heading-bar">
    //            <div className="container">
    //                <a className="heading-bar" href="https://bxcell.com"><FontAwesomeIcon icon={faCaretLeft} className="return-icon" /> Home</a>
    //            </div>
    //        </div>
    //        <div className="container">
    //            {props.children}
    //        </div>
    //    </>
    //);

    return props.children;
};
