import React, { useState, useEffect } from "react";
import "./ServiceRequest.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./serviceRequestValidation";
import Input from "../components/forms/Input";
import TextArea from "../components/forms/TextArea";
import Recaptcha from "react-google-recaptcha"

export default function ServiceRequest() {
    const [apiBaseUrl, setApiBaseUrl] = useState(null);
    const [apiKey, setApiKey] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [record, setRecord] = useState({});
    const [dataFiles, setDataFiles] = useState([]);
    const [referenceFiles, setReferenceFiles] = useState([]);

    const { register, handleSubmit, setValue, formState: { errors, ...formState } } = useForm({ resolver: yupResolver(validationSchema) });
    const inputProps = { register, errors };
    const { isSubmitting } = formState;

    const [applicationType, setApplicationType] = useState("");

    const [formValidationRequest, setFormValidationRequest] = useState(null);
    const [formValidationFailed, setFormValidationFailed] = useState(false);

    useEffect(() => {
        document.title = "Bio X Cell Technical Service Request";

        let apiBaseUrl = "";
        let apiKey = "";

        (async () => {
            await fetch("/api/settings", {
                method: "GET",
                headers: { Accept: "application/json", "Content-Type": "application/json" },
            })
                .then(response => response.text())
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.errors && response.errors.length) {
                        console.error(response);
                        return;
                    }

                    apiBaseUrl = response.apiBaseUrl;
                    setApiBaseUrl(apiBaseUrl);
                    apiKey = response.apiKey;
                    setApiKey(apiKey);
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    }, []);

    const handleOnSubmit = (model) => {
        setFormValidationFailed(false);
        setSubmitDisabled(true);

        let request = new FormData();

        request.append("formValidationRequest", formValidationRequest);

        request.append("dataFile", dataFiles[0]);
        request.append("dataFileName", dataFiles[0].name);
        request.append("referenceFile", referenceFiles[0]);
        request.append("referenceFileName", referenceFiles[0].name);
        request.append("givenName", model.givenName);
        request.append("familyName", model.familyName);
        request.append("organizationName", model.organizationName);
        request.append("email", model.email);
        request.append("purchaseOrderNumber", model.purchaseOrderNumber);
        request.append("receiptDate", model.receiptDate);
        request.append("catalogCode", model.catalogCode);
        request.append("lotNumber", model.lotNumber);
        request.append("sizeMg", model.sizeMg);
        request.append("application", model.application);
        request.append("storage", model.storage);
        request.append("applicationType", model.applicationType);
        request.append("dosage", model.dosage);
        request.append("administrativeRoute", model.administrativeRoute);
        request.append("mouseStrain", model.mouseStrain);
        request.append("tumorCell", model.tumorCell);
        request.append("protocolDetails", model.protocolDetails);
        request.append("anticipatedResults", model.anticipatedResults);

        (async () => {
            await fetch(`${apiBaseUrl}/forms/technical-service`, {
                method: "POST",
                headers: { "x-api-key": apiKey },
                body: request,
            })
                .then(response => {
                    return response.text()
                })
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.status >= 400) {
                        setSubmitDisabled(false);
                        console.error(response);
                        throw new Error(`Error calling API.`);
                    }

                    if (!response.formValidationSuccess) {
                        setFormValidationFailed(true);
                        setSubmitDisabled(false);
                        return;
                    }

                    setRecord(response);
                    setCompleted(true);

                    setSubmitDisabled(false);
                })
                .catch(error => {
                    setSubmitDisabled(false);
                    console.error(error);
                });
        })();
    };

    const handleApplicationTypeChange = (event) => {
        setValue("applicationType", event.target.value);
        setApplicationType(event.target.value);
    };

    const handleRecaptchaChange = (value) => {
        setFormValidationRequest(value);
    };

    return (
        <>

            <div className="container">

                <div style={{ display: completed ? "" : "none" }}>

                    <h1>Thank You!</h1>

                    <p>
                        {record.givenName},
                    </p>

                    <p>
                        Thank you for submitting your technical service request. A representative will be in touch shortly.
                    </p>

                </div>

                <div style={{ display: completed ? "none" : "" }}>

                    <h1>Technical Service Request</h1>

                    <div className={`${formValidationFailed ? "" : "d-none"} alert alert-danger`}>
                        Please check the reCAPTCHA checkbox below.
                    </div>

                    <p>
                        Please complete the form below to submit your technical service request.
                    </p>

                    <form onSubmit={handleSubmit(handleOnSubmit)}>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input id="givenName"
                                    label="First Name"
                                    {...inputProps} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input id="familyName"
                                    label="Last Name"
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input id="organizationName"
                                    label="Organization"
                                    {...inputProps} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input id="email"
                                    type="email"
                                    label="E-Mail Address"
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input id="purchaseOrderNumber"
                                    label="Order or PO #"
                                    description="Order or Purchase Order (PO) that includes the product."
                                    {...inputProps} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input id="receiptDate"
                                    type="date"
                                    label="Date Received"
                                    description="Date the product was received (approximate if unknown)."
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input id="catalogCode"
                                    label="Catalog #"
                                    description="Catalog number of the product."
                                    {...inputProps} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input id="lotNumber"
                                    label="Lot Number"
                                    description="Lot number from CoA or vial label."
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <select id="sizeMg"
                                        {...register("sizeMg")}
                                        className={`form-select ${errors["sizeMg"] ? "is-invalid" : ""}`}>
                                        <option value=""></option>
                                        <option value="1">1 mg</option>
                                        <option value="5">5 mg</option>
                                        <option value="25">25 mg</option>
                                        <option value="50">50 mg</option>
                                        <option value="100">100 mg</option>
                                    </select>
                                    <label htmlFor="sizeMg" className="form-label">Size (mg)</label>
                                    <div className="invalid-feedback">{errors["sizeMg"]?.message}</div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input id="application"
                                    label="Application"
                                    description="Application for which the product was used."
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input id="storage"
                                    label="Product Storage"
                                    description="Description of how the product was stored and diluted prior to use."
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <select style={{ fontStyle: "italic" }} id="applicationType"
                                        {...register("applicationType")}
                                        className={`form-select ${errors["applicationType"] ? "is-invalid" : ""}`}
                                        onChange={handleApplicationTypeChange}>
                                        <option value=""></option>
                                        <option value="in vivo" style={{ fontStyle: "italic" }}>in vivo</option>
                                        <option value="in vitro" style={{ fontStyle: "italic" }}>in vitro</option>
                                    </select>
                                    <label htmlFor="applicationType" className="form-label">Application Type</label>
                                    <div className="invalid-feedback">{errors["applicationType"]?.message}</div>
                                </div>
                            </div>
                        </div>

                        {applicationType === "in vivo" ? 
                            <>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <Input id="dosage"
                                            label="Dosage"
                                            description="Dosage of antibody per animal."
                                            {...inputProps} />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Input id="administrativeRoute"
                                            label="Administration Route"
                                            description="Route of administration (i.e. I.V., I.P., etc.)."
                                            {...inputProps} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <Input id="mouseStrain"
                                            label="Mouse Strain"
                                            {...inputProps} />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Input id="tumorCell"
                                            label="Tumor Cell"
                                            description="Where applicable, type of tumor cell used (i.e. MC38, CT26, 4T1, etc.)."
                                            {...inputProps} />
                                    </div>
                                </div>
                            </>
                        : null}

                        {applicationType === "in vitro" ?
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <TextArea id="protocolDetails"
                                        label="Protocol Details"
                                        height={100}
                                        description="Please provide all relevant protocol details (i.e. concentration of antibody, cell line used, sample type, etc.)."
                                        {...inputProps} />
                                </div>
                            </div>
                        : null}

                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <TextArea id="anticipatedResults"
                                    label="Anticipated Results"
                                    height={100}
                                    description="Results you expected but did not observe."
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <label className="form-label">Experimental Data and Details</label>
                                <input className="form-control" type="file" onChange={(event) => setDataFiles(event.target.files)} placeholder="PDF" />
                                <div className="form-text">Data and details should identify and display the issue as clearly and concisely as possible. All graph axis should be labelled (including FACS plots). All sample names should be clear and well explained. If you have data from a previous experiment using the same product but obtained a different result please include that data as well.</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <label className="form-label">Published Reference</label>
                                <input className="form-control" type="file" onChange={(event) => setReferenceFiles(event.target.files)} placeholder="PDF" />
                                <div className="form-text">Please provide a published reference (as a PDF) which uses this antibody in the same type of experiment as you have but produced the anticipated result.</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <Recaptcha sitekey="6LcgQtciAAAAAPYj_MRfIcJ6zshQ9nokwtewlV98" onChange={handleRecaptchaChange} />
                            </div>
                        </div>

                        <div className={`${formValidationFailed ? "" : "d-none"} alert alert-danger`}>
                            Please check the reCAPTCHA checkbox above.
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-3 mb-3">
                                <button className="btn btn-primary w-100" type="submit" disabled={submitDisabled || isSubmitting}>Submit Request</button>
                            </div>
                        </div>

                    </form>

                </div>

            </div>

        </>
    );

};
