import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./newLabDiscountValidation";
import Input from "../components/forms/Input";
import TextArea from "../components/forms/TextArea";
import Recaptcha from "react-google-recaptcha"

export default function NewLabDiscount() {
    const [apiBaseUrl, setApiBaseUrl] = useState(null);
    const [apiKey, setApiKey] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [record, setRecord] = useState({});

    const { register, handleSubmit, formState: { errors, ...formState } } = useForm({ resolver: yupResolver(validationSchema) });
    const inputProps = { register, errors };
    const { isSubmitting } = formState;

    const [formValidationRequest, setFormValidationRequest] = useState(null);
    const [formValidationFailed, setFormValidationFailed] = useState(false);

    useEffect(() => {
        document.title = "Bio X Cell New Lab Discount Request";

        let apiBaseUrl = "";
        let apiKey = "";

        (async () => {
            await fetch("/api/settings", {
                method: "GET",
                headers: { Accept: "application/json", "Content-Type": "application/json" },
            })
                .then(response => response.text())
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.errors && response.errors.length) {
                        console.error(response);
                        return;
                    }

                    apiBaseUrl = response.apiBaseUrl;
                    setApiBaseUrl(apiBaseUrl);
                    apiKey = response.apiKey;
                    setApiKey(apiKey);
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    }, []);

    const handleOnSubmit = (request) => {
        setFormValidationFailed(false);
        setSubmitDisabled(true);

        //request.append("formValidationRequest", formValidationRequest);
        request.formValidationRequest = formValidationRequest;

        (async () => {
            await fetch(`${apiBaseUrl}/forms/new-lab-discount`, {
                method: "POST",
                headers: { Accept: "application/json", "Content-Type": "application/json", "x-api-key": apiKey },
                body: JSON.stringify(request),
            })
                .then(response => {
                    if (response.status === 401) {
                        document.location.href = `https://bxcell.com`;
                    }

                    return response.text()
                })
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.status >= 400) {
                        setSubmitDisabled(false);
                        console.error(response);
                        throw new Error(`Error calling API.`);
                    }

                    if (!response.formValidationSuccess) {
                        setFormValidationFailed(true);
                        setSubmitDisabled(false);
                        return;
                    }

                    setRecord(response);
                    setCompleted(true);

                    setSubmitDisabled(false);
                })
                .catch(error => {
                    setSubmitDisabled(false);
                    console.error(error);
                });
        })();
    };

    const handleRecaptchaChange = (value) => {
        setFormValidationRequest(value);
    };

    return (
        <>

            <div className="container">

                <div style={{ display: completed ? "" : "none" }}>

                    <h1>Thank You!</h1>

                    <p>
                        {record.givenName},
                    </p>

                    <p>
                        Thank you for submitting your new lab discount request.  A representative will be in touch shortly.
                    </p>

                </div>

                <div style={{ display: completed ? "none" : "" }}>

                    <h1>New Lab Discount Request</h1>

                    <div className={`${formValidationFailed ? "" : "d-none"} alert alert-danger`}>
                        Please check the reCAPTCHA checkbox below.
                    </div>

                    <p>
                        Please complete the form below to request your new lab discount.
                    </p>

                    <form onSubmit={handleSubmit(handleOnSubmit)}>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input id="givenName"
                                    label="First Name"
                                    {...inputProps} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input id="familyName"
                                    label="Last Name"
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input id="email"
                                    type="email"
                                    label="E-Mail Address"
                                    {...inputProps} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input id="title"
                                    label="Position at the Lab"
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input id="organizationName"
                                    label="Institution/Organization"
                                    {...inputProps} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input id="laboratoryFounded"
                                    type="date"
                                    label="Date Lab was Founded"
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input id="laboratoryName"
                                    label="Name of Lab"
                                    {...inputProps} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input id="estimatedSpend"
                                    label="Estimated Spend"
                                    description="Estimated amount to be spent on Bio X Cell products within the next 2 months."
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input id="areaOfResearch"
                                    label="Area of Research"
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <TextArea id="referralAnnotation"
                                    label="How did you hear about Bio X Cell?"
                                    height={100}
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <Recaptcha sitekey="6LcgQtciAAAAAPYj_MRfIcJ6zshQ9nokwtewlV98" onChange={handleRecaptchaChange} />
                            </div>
                        </div>

                        <div className={`${formValidationFailed ? "" : "d-none"} alert alert-danger`}>
                            Please check the reCAPTCHA checkbox above.
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-3">
                                <button className="btn btn-primary w-100" type="submit" disabled={submitDisabled || isSubmitting}>Submit New Lab Discount Request</button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-3 mb-3">
                                Once submitted, applications will be reviewed within 5 business days. E-mail communication about the discount program will be sent to the e-mail address you use to send your application. If you do not get an e-mail confirmation following your form submission, please contact us at techsupport@bioxcell.com.
                            </div>
                        </div>

                    </form>

                </div>

            </div>

        </>
    );
};
