import React from "react";

export default function TextArea(props) {
    const { id, label, description, register, errors, height, visible, disabled } = props;

    let isVisible = visible;
    if (typeof (isVisible) === "undefined") isVisible = true;

    return (
        <div className={`form-floating ${!isVisible ? "d-none" : ""}`}>
            <textarea id={id} style={{ height: `${height}px` }} placeholder={label}
                {...register(id)}
                className={`form-control ${errors[id] ? "is-invalid" : ""}`}
                disabled={disabled}>
            </textarea>
            <label htmlFor={id}>{label}</label>
            <div className="invalid-feedback">{errors[id]?.message}</div>
            {description ? <div className="form-text">{description}</div> : null}
        </div>
    );
}
