import React from "react";

export default function MapTestingDescription({ mapTesting }) {
    if (mapTesting === 1) {
        return (
            <div className="form-row">
                <div className="col-md-12 mb-3">
                    <h5>MAP testing 1 includes:</h5>
                    <ul>
                        <li>Assay S0034: Ultrasensitive qualitative detection of mouse hepatitis virus (MHV) by reverse transcription coupled real time PCR</li>
                        <li>Assay S0098: Ultrasensitive qualitative detection but not differentiation of mouse minute virus (MMV) and mouse parvovirus (MPV) by real time PCR</li>
                        <li>Assay S0099: Ultrasensitive qualitative detection of Sendai Virus (SEND) by reverse transcription coupled real time PCR</li>
                        <li>Assay S0102: Ultrasensitive qualitative detection of Theiler's murine encephalomyelitis virus by reverse transcription coupled real time PCR</li>
                        <li>Assay S0139: Ultrasensitive qualitative detection of pneumonia virus of mice by reverse transcription coupled real time PCR</li>
                    </ul>
                </div>
            </div>
        );
    }

    if (mapTesting === 2) {
        return (
            <div className="form-row">
                <div className="col-md-12 mb-3">
                    <h5>MAP testing 2 includes:</h5>
                    <ul>
                        <li>Assay B0009: Ultrasensitive Mycoplasma screen by real time PCR. This screen detects but does not differentiate M. arginini, M. fermentans, M. hominis, M. hyorhinis, M. orale, M. pirum, M. salivarium, M. agassizii, M. cynos and others. This screen does not detect M. pneumoniae or M. pulmonis.</li>
                        <li>Panel P0029: Mouse Essentials Panel - Ultrasensitive detection and differentiation of the following pathogens using real time PCR protocols: MNV, MPV 1/2/3/4/5, MMV/MVM, MHV, REO-1, REO-2, REO-3, LCMV, LDV, MRV/EDIM, TMEV, ECTRO, HANTA, POLY, MAD1/MAV1, MAD2/MAV2, SEND, Mycoplasma pulmonis, PVM, mouse CMV, and K virus.</li>
                    </ul>
                </div>
            </div>
        );
    }

    return <></>;
}