import React, { useState, useEffect } from "react";
import "./AccountsPayable.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./accountsPayableValidation";
import Input from "../components/forms/Input";
import Recaptcha from "react-google-recaptcha"

export default function AccountsPayable() {
    const [apiBaseUrl, setApiBaseUrl] = useState(null);
    const [apiKey, setApiKey] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [record, setRecord] = useState({});
    const [files, setFiles] = useState([]);

    const { register, handleSubmit, formState: { errors, ...formState } } = useForm({ resolver: yupResolver(validationSchema) });
    const inputProps = { register, errors };
    const { isSubmitting } = formState;

    const [formValidationRequest, setFormValidationRequest] = useState(null);
    const [formValidationFailed, setFormValidationFailed] = useState(false);

    useEffect(() => {
        document.title = "Bio X Cell Accounts Payable Invoice/Bill Submission";

        let apiBaseUrl = "";
        let apiKey = "";

        (async () => {
            await fetch("/api/settings", {
                method: "GET",
                headers: { Accept: "application/json", "Content-Type": "application/json" },
            })
                .then(response => response.text())
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.errors && response.errors.length) {
                        console.error(response);
                        return;
                    }

                    apiBaseUrl = response.apiBaseUrl;
                    setApiBaseUrl(apiBaseUrl);
                    apiKey = response.apiKey;
                    setApiKey(apiKey);
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    }, []);

    const handleOnSubmit = (model) => {
        setFormValidationFailed(false);
        setSubmitDisabled(true);

        let request = new FormData();

        request.append("formValidationRequest", formValidationRequest);

        request.append("formFile", files[0]);
        request.append("fileName", files[0].name);
        request.append("givenName", model.givenName);
        request.append("familyName", model.familyName);
        request.append("organizationName", model.organizationName);
        request.append("email", model.email);
        request.append("invoiceNumber", model.invoiceNumber);
        request.append("total", model.total);

        console.log(request);

        (async () => {
            await fetch(`${apiBaseUrl}/forms/accounts-payable`, {
                method: "POST",
                headers: { "x-api-key": apiKey },
                body: request,
            })
                .then(response => {
                    return response.text()
                })
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.status >= 400) {
                        setSubmitDisabled(false);
                        console.error(response);
                        throw new Error(`Error calling API.`);
                    }

                    if (!response.formValidationSuccess) {
                        setFormValidationFailed(true);
                        setSubmitDisabled(false);
                        return;
                    }

                    setRecord(response);
                    setCompleted(true);

                    setSubmitDisabled(false);
                })
                .catch(error => {
                    setSubmitDisabled(false);
                    console.error(error);
                });
        })();
    };

    const handleRecaptchaChange = (value) => {
        setFormValidationRequest(value);
    };

    return (
        <>

            <div style={{ display: completed ? "" : "none" }}>

                <h1>Thank You!</h1>

                <p>
                    {record.givenName},
                </p>

                <p>
                    Thank you for submitting your invoice.
                </p>

            </div>

            <div style={{ display: completed ? "none" : "" }}>

                <h1>Bio X Cell Accounts Payable Invoice/Bill Submission</h1>

                <div className={`${formValidationFailed ? "" : "d-none"} alert alert-danger`}>
                    Please check the reCAPTCHA checkbox below.
                </div>

                <p>
                    Please complete the form below and upload a digital copy of your invoice or bill.
                </p>

                <form onSubmit={handleSubmit(handleOnSubmit)}>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Input id="givenName"
                                label="Contact First Name"
                                {...inputProps} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input id="familyName"
                                label="Contact Last Name"
                                {...inputProps} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Input id="email"
                                type="email"
                                label="Contact E-Mail Address"
                                {...inputProps} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input id="organizationName"
                                label="Company Name"
                                {...inputProps} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <Input id="invoiceNumber"
                                label="Invoice Number"
                                {...inputProps} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input id="total"
                                type="number"
                                step="0.01"
                                label="Total Amount"
                                {...inputProps} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mt-3 mb-3">
                            <p>Click Choose File below to browse your computer for the invoice/bill PDF or JPG file.</p>
                            <input className="form-control" type="file" onChange={(event) => setFiles(event.target.files)} placeholder="PDF" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 mt-3 mb-3">
                            <Recaptcha sitekey="6LcgQtciAAAAAPYj_MRfIcJ6zshQ9nokwtewlV98" onChange={handleRecaptchaChange} />
                        </div>
                    </div>

                    <div className={`${formValidationFailed ? "" : "d-none"} alert alert-danger`}>
                        Please check the reCAPTCHA checkbox above.
                    </div>

                    <div className="row">
                        <div className="col-md-12 mt-3 mb-3">
                            <button className="btn btn-primary w-100" type="submit" disabled={submitDisabled || isSubmitting}>Submit Invoice/Bill</button>
                        </div>
                    </div>

                </form>

            </div>

        </>
    );
};
