import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./employmentOpeningValidation";
import Input from "../components/forms/Input";
import Recaptcha from "react-google-recaptcha"

export default function EmploymentOpening(props) {
    const [apiBaseUrl, setApiBaseUrl] = useState(null);
    const [apiKey, setApiKey] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [record, setRecord] = useState({});
    const [files, setFiles] = useState([]);
    const [positionCode, setPositionCode] = useState("");
    const [positionTitle, setPositionTitle] = useState("");

    const { register, handleSubmit, formState: { errors, ...formState } } = useForm({ resolver: yupResolver(validationSchema) });
    const inputProps = { register, errors };
    const { isSubmitting } = formState;

    const [formValidationRequest, setFormValidationRequest] = useState(null);
    const [formValidationFailed, setFormValidationFailed] = useState(false);

    const Position = (props) => {
        //if (props.code === "299a7803-7cbb-4d9d-afb9-8febc16475dc") {
        //    return (
        //        <>
        //            <h2>Inside Sales Manager</h2>
        //            <p>Bio X Cell is the world's leading supplier of functional monoclonal antibodies used for in vivo preclinical studies in animal models. Our antibodies are well established and have extensive publication records. Since 1997, Bio X Cell has been manufacturing high quality antibodies for biomedical research in our beautiful headquarters in Lebanon, NH - gateway to the Green and White Mountains.</p>
        //            <p>Bio X Cell is seeking a professional individual with high energy and a great personality for a position as Inside Sales Manager in our fast-paced, team-oriented environment. The Inside Sales Manager will drive U.S. territory sales, generate more interactions between the Bio X Cell sales team and the customer, address sales-related customer service inquiries, and improve the customer experience by establishing account level support.</p >
        //            <h3>Responsibilities</h3>
        //            <ul>
        //                <li>Understand and demonstrate Bio X Cell's products and services to customers.</li>
        //                <li>Meet sales goals and objectives set by leadership.</li>
        //                <li>Represent Bio X Cell at trade shows as needed by sales and marketing team.</li>
        //                <li>Develop meaningful relationships with customers to encourage trust and loyalty.</li>
        //                <li>Generate qualified leads for outside sales team.</li>
        //                <li>Track opportunities in a CRM.</li>
        //                <li>Engage in cold calling and emailing.</li>
        //                <li>Provide customers with low level technical support.</li>
        //                <li>Provide sales reports at monthly sales and marketing meetings.</li>
        //                <li>Stay current on industry trends, product developments, and competitor offerings.</li>
        //            </ul>
        //            <h3>Qualifications</h3>
        //            <ul>
        //                <li>BS in Biology, Chemistry or related life science discipline.</li>
        //                <li>1+ years inside sales or customer service/tech support experience.</li>
        //                <li>Strong interpersonal and communication skills.</li>
        //            </ul>
        //        </>
        //    );
        //}

        //if (props.code === "e3c446f2-fed3-4962-8ddd-fd8b8cc4aaaf") {
        //    return (
        //        <>
        //            <h2>Territory Sales Manager</h2>
        //            <p>Bio X Cell is the world's leading supplier of functional monoclonal antibodies used for in vivo preclinical studies in animal models. Our antibodies are well established and have extensive publication records. Since 1997, Bio X Cell has been manufacturing high quality antibodies for biomedical research in our beautiful headquarters in Lebanon, NH - gateway to the Green and White Mountains.</p>
        //            <p>Bio X Cell is seeking a professional individual with high energy and a great personality for a position as Scientific Territory Sales Manager in our fast-paced, team-oriented environment. This position is a great opportunity to drive the business in a high growth territory (TX, AR, LA, MS, AL, GA, FL, TN, KY, SC) at a growing biotech company and play an integral role in the success of Bio X Cell's mission to provide the preclinical research community the high-quality antibodies they need.</p >
        //            <p>The Territory Sales Manager is responsible for maintaining customer relationships, meeting sales targets, and ensuring efficient sales operations within a specific geographic area.They devise sales strategies, analyze data, address customer concerns, and identify sales opportunities.  The Territory Sales Manager plays a crucial role in driving revenue growth and expanding business within their assigned territory.  <strong>This position requires frequent ground travel in the Houston, TX and College Station, TX areas, along with occasional travel throughout the assigned area.</strong></p >
        //            <h3>Responsibilities</h3>
        //            <ul>
        //                <li>Complete the sales commitment to the company.</li>
        //                <li>Establish and maintain good relationship with customers, understand customer's need and report to management in real time.</li>
        //                <li>Identify and capture new business opportunities and build a good customer network.</li>
        //                <li>Make sales forecasts, sales plans, and sales reports on regular basis.</li>
        //                <li>Participate company's local marketing activities, such as trade shows, etc.</li>
        //                <li>Applies knowledge of the field and product features to match products to the needs of the client.</li>
        //                <li>Other tasks assigned by leaders.</li>
        //            </ul>
        //            <h3>Qualifications</h3>
        //            <ul>
        //                <li>BS or MS degree or above in life sciences</li>
        //                <li>2 years sales-related experience in life science related reagent or services, (prefer protein & antibody field)</li>
        //                <li>Good business development, marketing, and customer relationship skills</li>
        //                <li>Professional, enthusiastic and confident spirit</li>
        //                <li>Willing to travel frequently on assigned territory</li>
        //            </ul>
        //        </>
        //    );
        //}

        if (props.code === "a7863935-c2f7-4264-b41e-8c8801fd24da") {
            return (
                <>
                    <h2>Marketing Communications Specialist</h2>
                    <p>We are currently seeking a highly motivated, results-oriented, and collaborative individual to join us as the Marketing Communications Specialist. The ideal candidate will excel in a dynamic role, taking on diverse responsibilities, gaining hands-on experience in various marketing projects, and leading their own initiatives. This person will play an integral role in the success of Bio X Cell's brand awareness and further penetration into the antibody marketplace. At Bio X Cell, we value a passion for science, dedication to promoting top-quality products to aid scientific discoveries, and a commitment to finding purpose beyond professional duties, including contributing back to the community. This full-time position is open to a hybrid work schedule with two to three days a week in the office.</p>
                    <h3>Responsibilities</h3>
                    <ul>
                        <li>Support the Director of Marketing and Brand with ongoing marketing projects and efforts, while spearheading new initiatives.</li>
                        <li>Contribute to email marketing campaigns by planning and developing email content to boost customer engagement, nurture leads, and contribute to the overall success of the company's email marketing objectives.</li>
                        <li>Enhancing the company's online presence through collaboration on SEO and PPC initiatives to drive website traffic and boost eCommerce revenue.</li>
                        <li>Ensure optimal performance of our website by regularly checking, refreshing and updating content. Manage the company's news and blog posts, develop content that highlights our company's mission and values, which includes managing the Bio X Cell philanthropy page, documenting efforts, overseeing the Biofund page and contributions, and showcasing the company's green and sustainability initiatives, as well as community services.</li>
                        <li>Manage social media marketing content to amplify Bio X Cell's social media presence, enhancing social media reputation and recognition.</li>
                        <li>Coordinate customer surveys and collecting VOC feedback. Conduct market research and KOL interviews to gain insights into our market, product usage, consumer trend, and identify potential areas for development and growth. Assist in assessing our competitions, potential threats, and develop mitigating strategies.</li>
                        <li>Oversee sponsorships and customer-facing programs, including the Bio X Cell Travel grant program, discovery program, and product feedback initiatives.</li>
                        <li>Manage marketing materials; sourcing and negotiating prices, documenting costs, managing inventory, fulfilling requests, and coordinating materials for conferences and events.</li>
                        <li>Coordinate both domestic and international conferences/events, managing the preparation of marketing materials, inbound and outbound shipping, and ensuring that all conference deadlines are met. Occasional travel to conferences and events may be needed.</li>
                        <li>Contribute to the creation of marketing visuals, including print and digital advertisements.</li>
                        <li>Manage additional projects as assigned by management.</li>
                    </ul>
                    <h3>Qualifications - Education and Experience</h3>
                    <ul>
                        <li>Bachelor's degree in life science or marketing.</li>
                        <li>Minimum of 3 years of work experience in a life science company is preferred.</li>
                        <li>Master's degree, MBA, is preferred.</li>
                        <li>Self-starter, highly motivated, result-oriented, and a team player.</li>
                        <li>Excellent writing and communication skills.</li>
                        <li>Ability to work both independently and effectively in a team environment.</li>
                        <li>Strong organizational and problem-solving skills.</li>
                        <li>Experience with Adobe Creative Cloud.</li>
                    </ul>
                </>
            );
        }

        //if (props.code === "d1b59abb-f7ae-41f0-8d80-8a904cd4931a") {
        //    return (
        //        <>
        //            <h2>Scientific Territory Sales Manager - West Coast</h2>
        //            <p>
        //                <strong><em>(Must reside in San Diego Metropolitan Area)</em></strong>
        //            </p>
        //            <p>
        //                Bio X Cell is the world's leading supplier of functional monoclonal antibodies used for in vivo preclinical studies in animal models. Our antibodies are well established and have extensive publication records. Since 1997, Bio X Cell has been manufacturing high quality antibodies for biomedical research in our beautiful headquarters in Lebanon, NH - gateway to the Green and White Mountains. 
        //            </p>
        //            <p>
        //                Employees of Bio X Cell enjoy an amazing company culture filled with the company's employee focused approach, ensuring the well-being of everyone, including our communities that we live and work in. We cultivate an environment of collaboration, innovation, and support where every member of our team can share ideas and work together to bring those ideas to life. Bio X Cell offers generous benefits including Health/Medical Insurance, Dental Insurance, 401k, paid community volunteer time, and paid time off. 
        //            </p>
        //            <p>
        //                We are currently seeking a professional, highly driven salesperson, with a great personality, who will be responsible for growing the business in the West Coast territory (specifically the San Diego Metropolitan area). This position is a great opportunity to drive the business in a high growth territory at a growing biotech company and play an integral role in the success of Bio X Cell's mission to provide the preclinical research community the high-quality antibodies they need. The candidate will report to Bio X Cell's Director of Domestic Sales. This is a full-time position.
        //            </p>
        //            <h3>Essential Functions</h3>
        //            <ul>
        //                <li>Complete the sales commitment to the company</li>
        //                <li>Establish and maintain good relationship with customers, understand customer's need and report to management in real time</li>
        //                <li>Identify and capture new business opportunities and build a good customer network</li>
        //                <li>Make sales forecasts, sales plans, and sales reports on regular basis</li>
        //                <li>Participate company's local marketing activities, such as trade shows, etc.</li>
        //                <li>Applies knowledge of the field and product features to match products to the needs of the client</li>
        //                <li>Other tasks assigned by leaders</li>
        //            </ul>
        //            <h3>Minimum Qualifications &#8211; Education and Experience</h3>
        //            <ul>
        //                <li>BS or MS degree or above in life sciences</li>
        //                <li>>2 years sales-related experience in life science related reagent or services, (prefer protein & antibody field)</li>
        //                <li>Good business development, marketing, and customer relationship skills</li>
        //                <li>Professional, enthusiastic and confident spirit</li>
        //                <li>Willing to travel frequently within assigned territory</li>
        //            </ul>
        //            <h3>What Bio X Cell has to offer:</h3>
        //            <ul>
        //                <li>Very competitive compensation with paid holidays and PTO</li>
        //                <li>Employer paid premiums for medical and dental insurance</li>
        //                <li>Access to an extremely generous 401(k)-retirement plan with company match</li>
        //                <li>Charitable gift matching</li>
        //                <li>A comfortable, friendly, safe and fun work environment</li>
        //                <li>Many fun company events, and epic holiday parties</li>
        //                <li>A paid Community Participation Program</li>
        //            </ul>
        //        </>
        //    );
        //}

        //if (props.code === "69c44e83-40d8-4b1a-b2f0-5b80ac2644a2") {
        //    return (
        //        <>
        //            <h2>Scientific Territory Sales Manager</h2>
        //            <h3>(Research Grade Antibodies and Production Services)</h3>
        //            <h3 className="mb-3">Remote Northeast Region (ME, MA, NH, VT, CT, RI, NY)</h3>

        //            <p>
        //                Bio X Cell is the world's leading supplier of functional monoclonal antibodies used for in vivo preclinical studies in animal models. Our antibodies are well established and have extensive publication records. Since 1997, Bio X Cell has been manufacturing high quality antibodies for biomedical research in our beautiful headquarters in Lebanon, NH - gateway to the Green and White Mountains.
        //            </p>

        //            <p>
        //                Employees of Bio X Cell enjoy an amazing company culture filled with the company's employee focused approach, ensuring the well-being of everyone, including our communities that we live and work in.  We cultivate an environment of collaboration, innovation, and support where every member of our team can share ideas and work together to bring those ideas to life. Bio X Cell offers generous benefits including Health/Medical Insurance, Dental Insurance, 401k, paid community volunteer time, and paid time off.
        //            </p>

        //            <p>
        //                We are currently seeking a professional, highly driven salesperson, with a great personality, who will be responsible for growing the business in the Northeast territory (ME, MA, NH, VT, CT, RI, NY). This position is a great opportunity to drive the business in a high growth territory at a growing biotech company and play an integral role in the success of Bio X Cell's mission to provide the preclinical research community the high-quality antibodies they need. The candidate will report to Bio X Cell's Director of Domestic Sales. This is a full-time position.
        //            </p>

        //            <h3>Responsibilities</h3>
        //            <ul>
        //                <li>Complete the sales commitment to the company.</li>
        //                <li>Establish and maintain good relationship with customers, understand customer's need and report to management in real time.</li>
        //                <li>Identify and capture new business opportunities and build a good customer network.</li>
        //                <li>Make sales forecasts, sales plans, and sales reports on regular basis.</li>
        //                <li>Participate company's local marketing activities, such as trade shows, etc.</li>
        //                <li>Applies knowledge of the field and product features to match products to the needs of the client.</li>
        //                <li>Other tasks assigned by leaders.</li>
        //            </ul>

        //            <h3>Qualifications</h3>
        //            <ul>
        //                <li>BS or MS degree or above in life sciences.</li>
        //                <li>2 or more years sales-related experience in life science related reagent or services (prefer protein and antibody field).</li>
        //                <li>Good business development, marketing, and customer relationship skills.</li>
        //                <li>Professional, enthusiastic and confident spirit.</li>
        //                <li>Willing to travel frequently within assigned territory.</li>
        //            </ul>

        //            <h3>Benefits</h3>
        //            <ul>
        //                <li>401(k)</li>
        //                <li>Dental insurance</li>
        //                <li>Health insurance</li>
        //                <li>Paid time off</li>
        //                <li>Competitive compensation</li>
        //            </ul>
        //            <h3>Schedule</h3>
        //            <ul>
        //                <li>Typically, 40 Hour work week</li>
        //                <li>Monday through Friday</li>
        //            </ul>
        //            <p>
        //                Job Type: Full - time<br />
        //                Work Location: Remote
        //            </p>
        //        </>
        //    );
        //}

        //if (props.code === "1c116ffc-ce7b-4041-b5fe-62a7825fda46") {
        //    return (
        //        <>
        //            <h2>Cell Culture Manufacturing Technician</h2>
        //            <p>
        //                Bio X Cell is the world's leading supplier of functional monoclonal antibodies used for in vivo preclinical 
        //                studies in animal models. Our antibodies are well established and have extensive publication records. 
        //                Since 1997, Bio X Cell has been manufacturing high quality antibodies for biomedical research in our 
        //                beautiful headquarters in Lebanon, NH &#8211; gateway to the Green and White Mountains. Bio X Cell is 
        //                seeking a professional individual with high energy and a great personality for a position as Cell Culture 
        //                Manufacturing Technician in our fast-paced, team oriented environment. This position is a great 
        //                opportunity to get your foot in the door of a dynamic and growing biotech company and play an integral 
        //                role in the success of Bio X Cell's mission to provide the preclinical research community the high quality 
        //                antibodies they need.
        //            </p>
        //            <p>
        //                We are currently seeking a Cell Culture Manufacturing Technician who will be responsible for the 
        //                general support of the Fermentation Department. Above and beyond the technical responsibilities of 
        //                this position; the ideal candidate will exhibit excellent communication skills, be able to work with a 
        //                diverse set of employee styles and will be a self-starter, motivated to excel within their department and 
        //                to collaborate with other groups as needed. This is a full-time position with a work schedule of Thursday through Monday or Friday through Tuesday.
        //            </p>
        //            <h3>Essential Functions</h3>
        //            <ul>
        //                <li>Perform mammalian cell culture daily duties such as determining cell density using a hemocytometer, feeding and adjusting the pH of cell cultures, freezing and thawing cell lines.</li>
        //                <li>Prepare solutions and media additions, as necessary.</li>
        //                <li>Assist with Fermentation equipment maintenance and supply restock. Including moving media supply as necessary. Maintain and clean laboratory equipment. Assist in the maintenance of gas resupply and liquid nitrogen dewars.</li>
        //                <li>Assist with Safety Management in the lab and assist in writing up guidelines for safety following OSHA guidelines. Follow Fermentation protocols as written. Assist in the regular review and update of Fermentation protocols.</li>
        //                <li>Provide support to Fermentation and any other projects as assigned by the Department Manager.</li>
        //                <li>Wash &amp; autoclave glassware and tubing.</li>
        //                <li>Provide PTO coverage to other members of the fermentation department.</li>
        //                <li>Attend morning department meetings as requested, and any other duties as required.  Follow all Safety Guidelines as required by Bio X Cell, handle hazardous materials appropriately as dictated by the SDS, and wear the appropriate PPE provided by Bio X Cell.</li>
        //                <li>Know the location of safety shower, eye wash stations and safety data sheets.</li>
        //            </ul>
        //            <h3>Minimum Qualifications &#8211; Education and Experience</h3>
        //            <ul>
        //                <li>Bachelor of Arts/Bachelor of Science in relevant technology</li>
        //                <li>2+ years of laboratory experience</li>
        //                <li>Skilled in mammalian cell culture technique (adherent or suspension cell culture) and aseptic processing</li>
        //                <li>Previous experience culturing CHO, HEK293, and hybridoma cell lines highly recommended</li>
        //                <li>Must be a self-starter, highly motivated, dependable, and a team player</li>
        //                <li>Must also have problem solving, analytical, and quantitative skills and able to work independently and effectively in a team environment</li>
        //                <li>Must have strong organizational, documentation, and communication skills</li>
        //            </ul>
        //            <h3>What Bio X Cell has to offer:</h3>
        //            <ul>
        //                <li>Very competitive compensation with paid holidays and PTO</li>
        //                <li>Employer paid premiums for medical and dental insurance</li>
        //                <li>Access to an extremely generous 401(k)-retirement plan with company match</li>
        //                <li>Charitable gift matching</li>
        //                <li>A comfortable, friendly, safe and fun work environment</li>
        //                <li>Many fun company events, and epic holiday parties</li>
        //                <li>A paid Community Participation Program</li>
        //            </ul>
        //        </>
        //    );
        //}

        return <></>;
    };

    useEffect(() => {
        document.title = "Bio X Cell Employment Opening";

        // Uncomment when new position is posted.
        if (!props.id) {
            window.location.href = "https://bioxcell.com";
            return <></>;
        }

        setPositionCode(props.id.toLowerCase());

        //if (props.id.toLowerCase() === "d1b59abb-f7ae-41f0-8d80-8a904cd4931a") {
        //    setPositionTitle("Scientific Territory Sales Manager - West Coast");
        //} else if (props.id.toLowerCase() === "1c116ffc-ce7b-4041-b5fe-62a7825fda46") {
        //    setPositionTitle("Cell Culture Manufacturing Technician");
        //} else if (props.id.toLowerCase() === "69c44e83-40d8-4b1a-b2f0-5b80ac2644a2") {
        //    setPositionTitle("Scientific Territory Sales Manager");
        //if (props.id.toLowerCase() === "e3c446f2-fed3-4962-8ddd-fd8b8cc4aaaf") {
        //    setPositionTitle("Territory Sales Manager");
        if (props.id.toLowerCase() === "a7863935-c2f7-4264-b41e-8c8801fd24da") {
            setPositionTitle("Marketing Communications Specialist");
        //} else if (props.id.toLowerCase() === "299a7803-7cbb-4d9d-afb9-8febc16475dc") {
        //    setPositionTitle("Inside Sales Manager");
        }

        let apiBaseUrl = "";
        let apiKey = "";

        (async () => {
            await fetch("/api/settings", {
                method: "GET",
                headers: { Accept: "application/json", "Content-Type": "application/json" },
            })
                .then(response => response.text())
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.errors && response.errors.length) {
                        console.error(response);
                        return;
                    }

                    apiBaseUrl = response.apiBaseUrl;
                    setApiBaseUrl(apiBaseUrl);
                    apiKey = response.apiKey;
                    setApiKey(apiKey);
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    }, []);

    const handleOnSubmit = (model) => {
        setFormValidationFailed(false);
        setSubmitDisabled(true);

        let request = new FormData();

        request.append("formValidationRequest", formValidationRequest);

        request.append("formFile", files[0]);
        request.append("fileName", files[0].name);
        request.append("givenName", model.givenName);
        request.append("familyName", model.familyName);
        request.append("email", model.email);
        request.append("positionCode", positionCode);
        request.append("positionTitle", positionTitle);

        (async () => {
            await fetch(`${apiBaseUrl}/forms/employment-application`, {
                method: "POST",
                headers: { "x-api-key": apiKey },
                body: request,
            })
                .then(response => {
                    return response.text()
                })
                .then(text => (text.length ? JSON.parse(text) : {}))
                .then(response => {
                    if (response.status >= 400) {
                        setSubmitDisabled(false);
                        console.error(response);
                        throw new Error(`Error calling API.`);
                    }

                    if (!response.formValidationSuccess) {
                        setFormValidationFailed(true);
                        setSubmitDisabled(false);
                        return;
                    }

                    setRecord(response);
                    setCompleted(true);

                    setSubmitDisabled(false);
                })
                .catch(error => {
                    setSubmitDisabled(false);
                    console.error(error);
                });
        })();
    };

    const handleRecaptchaChange = (value) => {
        setFormValidationRequest(value);
    };

    return (
        <>

            <div className="container">

                <div style={{ display: completed ? "" : "none" }}>

                    <h1>Thank You!</h1>

                    <p>
                        {record.givenName},
                    </p>

                    <p>
                        Thank you for your employment application.  We will contact you shortly.
                    </p>

                </div>

                <div style={{ display: completed ? "none" : "" }}>

                    <h1 className="mb-4">Employment Opening</h1>

                    <Position code={positionCode} />

                    <h1>Apply for This Position</h1>

                    <div className={`${formValidationFailed ? "" : "d-none"} alert alert-danger`}>
                        Please check the reCAPTCHA checkbox below.
                    </div>

                    <p>
                        Please complete the form below and upload a digital copy of your resume.
                    </p>

                    <form onSubmit={handleSubmit(handleOnSubmit)}>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input id="givenName"
                                    label="First Name"
                                    {...inputProps} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input id="familyName"
                                    label="Last Name"
                                    {...inputProps} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <Input id="email"
                                    type="email"
                                    label="E-Mail Address"
                                    {...inputProps} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <input className="form-control" type="file" onChange={(event) => setFiles(event.target.files)} placeholder="PDF or Microsoft Word" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <Recaptcha sitekey="6LcgQtciAAAAAPYj_MRfIcJ6zshQ9nokwtewlV98" onChange={handleRecaptchaChange} />
                            </div>
                        </div>

                        <div className={`${formValidationFailed ? "" : "d-none"} alert alert-danger`}>
                            Please check the reCAPTCHA checkbox above.
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-3 mb-3">
                                <button className="btn btn-primary w-100" type="submit" disabled={submitDisabled || isSubmitting}>Apply for Position</button>
                            </div>
                        </div>

                    </form>

                </div>

            </div>

        </>
    );
};
